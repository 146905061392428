<template>
  <div class="logorder-continer">
    <div class="logorder-head">
        <div class="head-left">
            <div class="head-input">
                <el-input v-model="input"
                          placeholder="请输入物流单号"
                          size="mini"></el-input>
            </div>
            <div class="head-input">
                <el-input v-model="phone"
                          placeholder="请输入收件人电话"
                          size="mini"></el-input>
            </div>
            <div class="head-input">
                <el-input v-model="input2"
                          placeholder="请输入订单编号"
                          size="mini"></el-input>
            </div>
            <div class="head-select">
                <el-select slot="prepend" v-model="kd" placeholder="请选择快递公司" size="mini">
                    <el-option v-for="item in courier"
                               :key="item.companyId"
                               :label="item.name"
                               :value="item.companyId" />
                </el-select>
            </div>
            <div class="head-select">
                <el-select slot="prepend" v-model="shopId" filterable clearable placeholder="请选择商铺" size="mini">
                    <el-option v-for="item in shopInfo"
                               :key="item.shopId"
                               :label="item.name"
                               :value="item.shopId" />
                </el-select>
            </div>
            
            <div class="head-select">
                <el-select slot="prepend"
                           v-model="select2"
                           placeholder="全部"
                           size="mini">
                    <el-option label="全部" :value="0"> </el-option>
                    <el-option v-for="item in orderstatus"
                               :key="item.stateId"
                               :label="item.stateName"
                               :value="item.stateId" />
                </el-select>
            </div>
            <div class="head-input">
                <el-date-picker v-model="dateRangeVal"
                                type="daterange"
                                size="mini"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                </el-date-picker>
            </div>

        </div>
      <div class="head-right">
        <el-button type="primary" size="mini" @click="seek()">搜索</el-button>
        <el-button size="mini" type="info" @click="white()">重置</el-button>
        <el-button size="mini" @click="doOut">导出</el-button>
      </div>
    </div>
    <div class="logorder-center">
      <div class="center-head">
        <!-- <el-button size="mini" v-for="item in  orderstatus" :key="item.stateId" @click="conditionFn(item.stateId)">{{item.stateName}}  (111)</el-button> -->
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-menu-item :index="0"  @click="conditionFn(0)"> 全部 </el-menu-item>
          <el-menu-item
            v-for="(item, index) in orderstatus"
            :key="item.stateId"
            :index="(index + 1).toString()"
            @click="conditionFn(item.stateId)"
            >{{ item.stateName }}</el-menu-item
          >
        </el-menu>
      </div>
      <div class="center-table">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#d0e0f7' }"
          max-height="400"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            align="center"
            fixed
            prop="index"
            label="序号"
            width="50"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="orderNo"
            label="订单编号"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="goodsNum"
            label="包裹商品数量"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="merchantName"
            label="店铺名称"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="merchantId"
            label="店铺ID"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="paymentCompanyName"
            label="付款方公司名称"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="paymentCompanyId"
            label="付款公司ID"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="senderAddress"
            label="发货地"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="receiverAddress"
            label="收货地"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="receiverMobile"
            label="收件人电话"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="wayBillNo"
            label="物流单号"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="expressName"
            label="物流品牌"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="freight"
            label="物流费用（单位元）"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="couponName"
            label="优惠券名称"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="couponNo"
            label="优惠券编号"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="couponAmount"
            label="优惠金额（单位元）"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="couponNo"
            label="是否保价"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="isInsuredPrice"
            label="保价费用（单位元）"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="actualPayAmount"
            label="实付金额（单位元）"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="payWay"
            label="支付方式"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="actualWeight"
            label="重量（单位kg）"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="estimatedVloumn"
            label="体积(单位cm）"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="stateName"
            label="物流订单状态"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timeSendOutGoods"
            label="发货时间"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timePickup"
            label="揽件时间"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timeSignFor"
            label="签收时间"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timeCompletion"
            label="物流订单完成时间"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timeSettlement"
            label="结算时间"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="cancelTime"
            label="取消发货时间"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="cancelType"
            label="取消发货类型"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="cancelReason"
            label="取消发货原因"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="cancelUserName"
            label="取消操作人"
            width="150"
          >
          </el-table-column>
          <el-table-column fixed="right" label="操作" prop="button" width="160">
            <template slot-scope="scope">
              <div class="cope">
                <el-button
                v-for="(item,index) in scope.row.button " :key="index"
                  @click.native.prevent="look(scope.row,item)"
                  type="text"
                  size="small"
                >
                {{item}}
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 15]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="sum"
        >
        </el-pagination>
      </div>
      <!-- <el-dialog title="取消发货" :visible.sync="dialogTableVisible">
        <div class="cancel">
          <el-descriptions size="mini" :column="1" border>
            <el-descriptions-item label-class-name="my-label" label="订单编号"
              >18100000000</el-descriptions-item
            >
            <el-descriptions-item label-class-name="my-label" label="收货人姓名"
              >18100000000</el-descriptions-item
            >
            <el-descriptions-item label-class-name="my-label" label="收货人电话"
              >18100000000</el-descriptions-item
            >
            <el-descriptions-item label-class-name="my-label" label="收货地址"
              >18100000000</el-descriptions-item
            >
          </el-descriptions>
          <div class="cause">
            <div class="cause-left">取消原因</div>
            <div class="cause-right">
              <div class="right-top">
                <div class="top-radio">
                  <el-radio v-model="radio" label="1">{{ '' }}</el-radio>
                </div>
                <div class="top-select1">
                  <el-select
                    slot="prepend"
                    v-model="select2"
                    placeholder="全部"
                    size="mini"
                  >
                    <el-option
                      v-for="item in orderstatus"
                      :key="item.stateId"
                      :label="item.stateName"
                      :value="item.stateId"
                    />
                  </el-select>
                </div>
                <div class="top-select1">
                  <el-select
                    slot="prepend"
                    v-model="select2"
                    placeholder="全部"
                    size="mini"
                  >
                    <el-option
                      v-for="item in orderstatus"
                      :key="item.stateId"
                      :label="item.stateName"
                      :value="item.stateId"
                    />
                  </el-select>
                </div>
              </div>
              <div class="right-end">
                <div class="top-radio">
                  <el-radio v-model="radio" label="2">其他</el-radio>
                </div>
              </div>
            </div>
          </div>
          <div class="cause-button">
            <el-button size="mini" plain @click="consider()"
              >考虑一下</el-button
            >
            <el-button type="primary" size="mini" plain>确认</el-button>
          </div>
        </div>
      </el-dialog> -->
      <!-- <el-dialog title="取消发货" :visible.sync="dialogTableVisible2">
        <div class="cancel2">
          <el-descriptions size="mini" :column="1" border>
            <el-descriptions-item label-class-name="my-label" label="订单编号"
              >18100000000</el-descriptions-item
            >
            <el-descriptions-item label-class-name="my-label" label="收货人姓名"
              >18100000000</el-descriptions-item
            >
            <el-descriptions-item label-class-name="my-label" label="收货人电话"
              >18100000000</el-descriptions-item
            >
            <el-descriptions-item label-class-name="my-label" label="收货地址"
              >18100000000</el-descriptions-item
            >
            <el-descriptions-item label-class-name="my-label" label="发货仓">
              <div class="top-select3">
                <el-select
                  slot="prepend"
                  v-model="select2"
                  placeholder="全部"
                  size="mini"
                >
                  <el-option
                    v-for="item in orderstatus"
                    :key="item.stateId"
                    :label="item.stateName"
                    :value="item.stateId"
                  />
                </el-select>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" label="物流品牌">
              <div class="top-select3">
                <el-select
                  slot="prepend"
                  v-model="select2"
                  placeholder="全部"
                  size="mini"
                >
                  <el-option
                    v-for="item in orderstatus"
                    :key="item.stateId"
                    :label="item.stateName"
                    :value="item.stateId"
                  />
                </el-select>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" label="快递单号"
              >18100000000</el-descriptions-item
            >
          </el-descriptions>
          <div class="cause">
            <div class="cause-left">更改原因</div>
            <div class="cause-right">
              <div class="right-top">
                <div class="top-radio">
                  <el-radio v-model="radio2" label="1">{{ '' }}</el-radio>
                </div>
                <div class="top-select1">
                  <el-select
                    slot="prepend"
                    v-model="select2"
                    placeholder="全部"
                    size="mini"
                  >
                    <el-option
                      v-for="item in orderstatus"
                      :key="item.stateId"
                      :label="item.stateName"
                      :value="item.stateId"
                    />
                  </el-select>
                </div>
                <div class="top-select1">
                  <el-select
                    slot="prepend"
                    v-model="select2"
                    placeholder="全部"
                    size="mini"
                  >
                    <el-option
                      v-for="item in orderstatus"
                      :key="item.stateId"
                      :label="item.stateName"
                      :value="item.stateId"
                    />
                  </el-select>
                </div>
              </div>
              <div class="right-end">
                <div class="top-radio">
                  <el-radio v-model="radio2" label="2">其他</el-radio>
                </div>
              </div>
            </div>
          </div>
          <div class="cause-button">
            <el-button size="mini" plain @click="consider2()">取消</el-button>
            <el-button type="primary" size="mini" plain>确认</el-button>
          </div>
        </div>
      </el-dialog> -->
    </div>
  </div>
</template>

<script>
import {
  logorderggetlist,
  OrderState,
  courier,
  service,
  Export,
  Drop_ShopInfo
} from '../../api/logorder';
export default {
  data() {
    return {
      input: '', //物流单号
      phone: '', //收件人电话
      select: '', //快递类型
      select2: '', //状态
      input2: '', //订单编号
      select3: '', //订单来源
      shopId:null,//商铺id
      currentPage4: 1, //当前页
      sum: 1, //总条数
      pageSize: 5, //每页几条
      tableData: [], //订单列表
      orderstatus: [], //订单状态列表
      courier: [], //快递公司列表
      service: [], //所属平台
      shopInfo: [],//商铺下拉列表
      dialogTableVisible: false, //取消快递弹窗
      radio: '1', //取消窗口单选
      dialogTableVisible2: false, //更改快递
      radio2: '1', //更改快递单选
      activeIndex: 0,
        kd: '',
        dateRangeVal:''
    };
  },
  methods: {
      //重置
    white(){
      this.input=''
       this.phone=''
        this.input2=''
        this.select2=''
        this.kd=''
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      console.log(this.pageSize);
      this.getlogorlist();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.getlogorlist();
    },
    //订单列表
    getlogorlist() {
      let data = {
        companyId:Number(this.kd),
        orderNo:this.input2,
        pageSize: this.pageSize,
        pageIndex: this.currentPage4,
        state: this.select2 ? Number(this.select2):0,
        wayBillNo: this.input,
        receiverMobile: this.phone,
        sourceServiceId: Number(this.select3),
        dataSourceType: 1,
        timeBegin: (this.dateRangeVal && this.dateRangeVal[0]) ? this.dateRangeVal[0] : "",
        timeEnd: (this.dateRangeVal && this.dateRangeVal[1]) ? this.dateRangeVal[1] : "",
        shopId: this.shopId ? this.shopId:0
      };
      // console.log(data)
      logorderggetlist(data)
        .then((res) => {
          console.log(res)
          if (res.code === 10000) {
            this.tableData = res.data;
            this.sum=res.total
          }
        })
        .catch((err) => {
          console.log(err);
        });
      },
      doOut() {
          let data = {
        companyId:Number(this.kd),
        orderNo:this.input2,
        pageSize: this.pageSize,
        pageIndex: this.currentPage4,
        state: this.select2 ? Number(this.select2) : 0,
        wayBillNo: this.input,
        receiverMobile: this.phone,
        sourceServiceId: Number(this.select3),
        dataSourceType: 1,
        timeBegin: (this.dateRangeVal && this.dateRangeVal[0]) ? this.dateRangeVal[0] : "",
        timeEnd: (this.dateRangeVal && this.dateRangeVal[1]) ? this.dateRangeVal[1] : "",
        shopId: this.shopId ? this.shopId : 0
      };
          console.log(data);
          // return window.location.href = "https://files.lawxp.com:443/amb/1630289965.xlsx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=lawxp%2F20210830%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210830T021924Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3a3be70ddc5b91955dcf915392263b5652d6e35d7a08e2722a25895c6bd2140a";
          Export(data)
              .then((res) => {
                  if (res.code == 10000) {
                      window.location.href = res.data;
                  } else {
                      this.$message.error(res.msg);
                  }
              })
              .catch((err) => {
                  console.log(err);
              });
      },
    //状态
    getOrderState() {
      OrderState()
        .then((res) => {
          if (res.code === 10000) {
            this.orderstatus = res.data;
            // console.log(this.orderstatus)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //快递公司
    getcourier() {
      courier()
        .then((res) => {
          // console.log(res)
          if (res.code === 10000) {
            this.courier = res.data;
            // console.log(this.courier)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //订单来源
    getService() {
      service()
        .then((res) => {
          // console.log(res)
          if (res.code === 10000) {
            this.service = res.data;
            // console.log(this.courier)
          }
        })
        .catch((err) => {
          console.log(err);
        });
      },
      //店铺列表
      getShopInfo() {
        Drop_ShopInfo()
              .then((res) => {
                  // console.log(res)
                  if (res.code === 10000) {
                      this.shopInfo = res.data;
                      // console.log(this.courier)
                  }
              })
              .catch((err) => {
                  console.log(err);
              });
      },

    look(val,item) {
      console.log(val,item)
      if(item==='查看订单详情'){
 this.$router.push({
        path: '/mccdetail',
        query: { id: val.logisticsCourierId },
      });
      }
     
    },
    //搜索
    seek() {
      // alert(11)
      this.getlogorlist();
    },
    //状态查找
    conditionFn(val) {
      this.select2 = val;
      this.getlogorlist();
    },
    //取消快递
    cancel(val) {
      console.log(val);
      this.dialogTableVisible = true;
    },
    //考虑一下
    consider() {
      this.dialogTableVisible = false;
    },
    //更改快递
    change() {
      this.dialogTableVisible2 = true;
    },
    //取消
    consider2() {
      this.dialogTableVisible2 = false;
    },
  
  },
  created() {
    // console.log(this.pageSize)

    this.getOrderState();
    this.getcourier();
    this.getService();
    this.getlogorlist();
    this.getShopInfo();
  },
};
</script>

<style scoped>
.logorder-continer {
  width: 100%;
  /* height: 500px; */
  /* padding: 10px 10px 10px 10px; */
  /* box-sizing: border-box; */
  /* background: #ddd; */
}
.logorder-head {
  width: 100%;
  height: 98px;
  background: white;
  border-radius: 5px;
  padding: 10px 0 0 0;
  margin-top: 15px;
    padding: 5px 0 0 5px;
   box-sizing: border-box;
   display: flex;
}
.head-left {
  /* width: 78%; */
  /* height: 100%; */
  /* float: left; */
      display: flex;
      flex: 3;
flex-wrap: wrap;
justify-content: space-around;
}
.head-right {
  /* width: 22%; */
  /* height: 100%; */
  /* float: left; */
  flex: 1;
}
.head-input {
  /* width: 194px; */
  /* height: 30px; */
  /* float: left; */
  /* margin-right: 15px;
  margin-bottom: 20px; */
}
.head-select {
  width: 104px;
  height: 30px;
  float: left;
  margin-right: 15px;
  margin-bottom: 20px;
}
.logorder-center {
  width: 100%;
  background: white;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 0 0 0;
  box-sizing: border-box;
}
.cope {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.cause {
  width: 100%;
  height: 87px;
  border: 1px solid #dddddd;
}
.cause-left {
  width: 199px;
  height: 100%;
  float: left;
  border-right: 1px solid #dddddd;
  background: #dddddd;
  text-align: center;
  line-height: 87px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
}
.cause-right {
  width: 391px;
  height: 100%;
  float: left;
  /* background: green; */
}
/deep/
  .el-descriptions__body
  .el-descriptions__table
  .el-descriptions-item__cell {
  text-align: center;
}
/deep/ .my-label {
  width: 200px;
  background: #dddddd;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
}
.right-top {
  width: 100%;
  height: 44px;
  border-bottom: 1px solid #dddddd;
}
.right-end {
  width: 100%;
  height: 43px;
}
.top-radio {
  float: left;
  margin-left: 37px;
  margin-top: 15px;
}
.top-select1 {
  width: 139px;
  float: left;
  margin-right: 20px;
  margin-top: 9px;
}
.cause-button {
  width: 200px;
  height: 40px;
  float: right;
  margin-top: 30px;
}
.cancel {
  height: 286px;
}
.cancel2 {
  height: 420px;
}
.top-select3 {
  width: 283px;
  float: left;
  margin-left: 40px;
  margin-top: 9px;
}


/deep/.el-input {
  width: 150px;
}
::v-deep .el-select {
  width: 150px;
}

</style>
